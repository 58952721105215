body {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-link {
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}

.page-title {
  margin: 2vmin;
  font-size: 7vmin;
  font-weight: bold;
  text-align: center;
  color: white;
}

.page-description {
  margin: 10vmin;
  text-align: center;
  color: white;
}

.vertical-gap {
  margin-bottom: 3vmin;
}

.above-title-margin {
  margin-top: 10vh;
}
